import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/seo';
import BreadCrumb from '@components/breadcrumb';
import Covid from '@views/covid';

const RenderBody = ({ doc }) => {
  const covid = doc.covid[0];

  return (
    <>
      <SEO title="Careers" />
      <BreadCrumb name="Careers" />
      <Covid data={covid} />
    </>
  );
};

export default ({ data }) => {
  const doc = data.prismic.allCovid19s.edges.slice(0, 1).pop();

  if (!doc) return null;
  return <RenderBody doc={doc.node} />;
};

export const query = graphql`
  {
    prismic {
      allCovid19s {
        edges {
          node {
            covid {
              text
            }
          }
        }
      }
    }
  }
`;
